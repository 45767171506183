.TextBoxRect {
  height: 20px;
  padding: 0px 10px 0px;
  border-radius: 4px;
  border: solid 1px #e7e9ea;
  background-color: #ffffff;
  outline: none;
}

.TextBoxFont {
  font-family: Source Sans Pro;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  vertical-align: text-top;
}

.TextBoxFatRect {
  height: 28px;
  padding: 5px 8px 6px;
  border-radius: 4px;
  border: solid 1px #e7e9ea;
  background-color: #ffffff;
  outline: none;
}
